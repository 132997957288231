<template>
  <div>
    <a-upload
      name="file"
      listType="picture-card"
      class="avatar-uploader"
      :class="typeClass"
      :showUploadList="false"
      :customRequest="uploadImg"
      :beforeUpload="beforeUpload"
    >
      <img v-if="imageUrl" width="100%" :src="imageUrl" alt="avatar" />
      <div v-else>
        <a-icon
          :type="loading ? 'loading' : 'plus'"
          style="color: #4772ff; font-size: 28px"
        />
      </div>
    </a-upload>
  </div>
</template>

<script>
export default {
  props: {
    typeClass: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  created() {},
  computed: {
    imageUrl() {
      return this.value;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    uploadImg(e) {
      let file = e.file;
      this.loading = true;
      this.$http
        .upload(file)
        .then((res) => {
          this.$emit("input", res.url);
          this.loading = false;
        })
        .catch((res) => {
          this.$message.error("上传图片出错了");
          this.loading = false;
        });
    },

    beforeUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      if (!isImg) {
        this.$message.error("只能允许上传图片");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片最大为2M");
        return false;
      }
      return isImg && isLt2M;
    },

    closeImg() {
      let imgUrl = "";
      this.$emit("input", imgUrl);
      this.stopBubbling(event);
    },

    stopBubbling(e) {
      e = window.event || e;
      if (e.stopPropagation) {
        e.stopPropagation(); //阻止事件 冒泡传播
      } else {
        e.cancelBubble = true; //ie兼容
      }
    },

    formatFloat(f, digit) {
      let m = Math.pow(10, digit);
      let num = Math.round(f * m) / m;
      return num;
    },
  },
};
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
  background: rgba(94, 64, 255, 0.06);
}

.avatar-uploader > .ant-upload.ant-upload-select-picture-card {
  margin: 0;
}

.avatar-uploader > .ant-upload img {
  object-fit: cover;
  width: 114px;
  height: 114px;
}

.avatar-uploader.idcard > .ant-upload {
  width: 190px;
  height: 120px;
}

.avatar-uploader.idcard > .ant-upload img {
  width: 176px;
  height: 106px;
}

.avatar-uploader.small > .ant-upload {
  width: 80px;
  height: 80px;
}

.avatar-uploader.small > .ant-upload img {
  width: 66px;
  height: 66px;
}

.mobile {
  width: 750px;
}

.mobile > .ant-upload {
  width: 750px;
}

.mobile > .ant-upload img {
  width: 750px;
}

.mobile.idcard > .ant-upload {
  width: 750px;
}

.mobile.idcard > .ant-upload img {
  width: 750px;
}
</style>
